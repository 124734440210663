import React, {Component, Fragment} from 'react';
import { Container, Col, Row } from 'reactstrap';

import './styled.css';
import { MdDone } from "react-icons/md";


export default class PortabilidadeDetalhe extends Component {
    render(){
        return(
            <Fragment>
            <div className="gradientProdutos">
            <div className="tituloProdutos">
                <div className="textoTituloProdutos">Portabilidade</div>
                <div className="textoConteudoProdutos">
                Portar sua operação, com um novo contrato e uma nova instituição, utilizando seu saldo devedor com a instituição atual e melhorando
                                suas ofertas e cláusulas contratuais!
                </div>
            </div>
            </div>


          <Container>
                
                <Row>
                <Col sm="12">


                <div className="movimentoTela">
                    <div className="texto-Titulo">O que é?</div>
                        <div className="texto-Conteudo"><MdDone/>É a transferência de um contrato de crédito de uma instituição financeira para outra instituição financeira, mediante a solicitação do cliente.</div>
                        <div className="texto-Titulo">
                            Quem pode fazer?
                        </div>
                        <div className="texto-Conteudo">
                            <div><MdDone/>Clientes que possuam no mínimo 01 parcela do consignado pago</div>
                            <div><MdDone/>Militares e pensionistas com margem positiva ou negativa.</div>
                            
                        </div>
                        <div className="texto-Titulo">
                            Quais são os benefícios?
                        </div>
                        <div className="texto-Conteudo">
                        <div><MdDone/>Disponibilidade de trocar para um banco que forneça mais benefícios</div>
                        <div><MdDone/>Valores liberados rápidos na conta -  ate 07 dias utéis</div>
                        <div><MdDone/>Valor de retorno sem alterar o valor da parcela</div>               
                        <div><MdDone/>Redução da tabela de juros</div>
                        <div><MdDone/>Não mexemos na margem consignável</div>
                        </div>

                </div>
                </Col>
                </Row></Container>
                
                </Fragment>
       
       )
    }
}