import React ,{Fragment, useState} from 'react';
import './styled.css';
import api from '../../Services/api';
import ReactLoading from 'react-loading';

import {imgBancos} from '../../Services/imgBancos';
import { FaCheck, FaWhatsapp} from "react-icons/fa";
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';

export default function Simulador({whatsapp}){

    const [orgao, setOrgao] = useState('');
    const [margem, setMargem] = useState(0);
    const [tabela, setTabela] = useState('');
    const [parcelas, setParcelas] = useState(0);
    const [resultado, setResultado] = useState([]);
    const [msgresultado, setMsgresultado] = useState('');
    const [loading, setLoading] = useState(false);



    const orgaoHandleChange = async (e) =>{
      await setOrgao(e.target.value)        
    }

    const margemHandleChange = async (e) =>{
      await setMargem(e.target.value)        
     }

    const tabelaHandleChange = async (e) =>{
        await setTabela(e.target.value)        
       }

    const parcelasHandleChange = async (e) =>{
        await setParcelas(e.target.value)        
       }

    const handleSubmit = async (e)=>{
        e.preventDefault();
                //ligando o loading
             await setLoading(true);

         if(!orgao || !margem || !tabela || !parcelas) {
              //desligando o loading
                 await setLoading(false);
            return alert('favor preencha os campos');
         }

         try{
            const obj = {
                orgao,
                margem,
                tabela,
                parcelas
            }

            const res = await api.post('/simulador/simular', obj);
                      
               if(res.data.length===0) await  setMsgresultado('Não foi Possivel Realizar a Simulação')
                else if(res.data.length!==0) await setMsgresultado('')
             await setResultado(res.data);

              
            //desligando o loading
            await setLoading(false);
            
         }
         catch(e){
             //desligando o loading
            await setLoading(false);
                console.error(e)
                
         }

    }



    return(
        <Fragment>
            <section className='sessaoSimulador'>
                {loading && (
                    <div className='loading'>
                        
                        <ReactLoading type='spinningBubbles' color='#ffc04b' height={'10rem'} width={'10rem'} />  
                        <span>Carregando Resultado ...</span>
                    </div>
                      
                )}
                <h2>Emprestimo com as menores taxas do mercado</h2>
         
                 <div className='containerSimulador'>

                <div className='textSimulador'> 
                   
                    <span><FaCheck size={25} color={'#69baa4'}/>Sem valores antecipados</span>
                    <span><FaCheck size={25} color={'#69baa4'}/>Parcelas em até 72 x</span>
                    <span><FaCheck size={25} color={'#69baa4'}/>Descontado diretamente em folha</span>

                    
                    <div className='casal-emprestimo'>
                            
                            <img className='foto-casal' src='https://res.cloudinary.com/daty3sgrj/image/upload/q_auto:good/v1568916407/Fotos/casal2_1_fr5lxa.jpg' alt='Emprestimo Consignado'/>
                            </div> 
                                    
                          
                    </div>

                    <div  className='formSimulador'>
   
   <Form onSubmit={handleSubmit}>
  <fieldset>
  <legend>Simule já</legend>
      <FormGroup>
          <Label for='orgao'>Orgão</Label>
           <Input
          id='orgao'
          type='select'
          name='orgao'
          onChange={orgaoHandleChange}
          value={orgao}
            >
          <option>Escolha</option>
          <option value='marinha'>Marinha</option>
          <option value='exercito'>Exercito</option>
          <option value='siape'>Siape</option>
          </Input>



      </FormGroup>
      
      <FormGroup>
       <Label for='margem'>Margem</Label>
       <Input
          id='margem'
          type='number'
          name='margem'
          placeholder='R$'
          onChange={margemHandleChange}
          value={margem}
          step="0.01"
       />
      </FormGroup>

      <FormGroup>
          <Label for='tabela'>Tabela</Label>
           <Input
          id='tabela'
          type='select'
          name='tabela'
          onChange={tabelaHandleChange}
          value={tabela}
            >
          <option>Escolha</option>
          <option value='flex'>Flex</option>
          <option value='normal'>Normal</option>
          </Input>
      </FormGroup>

      <FormGroup>
          <Label for='parcelas'>Quantidade de Parcelas</Label>
           <Input
          id='parcelas'
          type='select'
          name='parcelas'
          onChange={parcelasHandleChange}
          value={parcelas}
            >
          <option>Escolha</option>
          <option value='60'>60x</option>
          <option value='72'>72x</option>
          </Input>
      </FormGroup>

      
       
      <Button color="success" type="submit" >SIMULAR</Button>
      
      </fieldset>
  </Form>
                 

                </div>

                
                <div className='resultadoSimulador'>

             
                       
                       <fieldset >
                           <legend>Resultado</legend>

                            {/*carrega loading quando true*/}
                           {loading && (
                                 <div className='loading'>
                             <ReactLoading type='spinningBubbles' color='#25d366' height={'10rem'} width={'10rem'} />  
                              </div>     
                             )}
                                    {/*mensagem inicial*/}
                                   {!resultado.length && !loading && !msgresultado.length &&  (
                                      <p>Favor Preencher os Campos e Clicar em Simular</p>
                                   )}

                                        {/*mensagem erro*/}
                                   { msgresultado && (
                                      <p>{msgresultado}</p>
                                   )}



     
                                      { resultado && resultado.map(result=>(
                                      

                                          
                                         <ul key={result._id}>

                                         
                                     <img src={imgBancos(result.banco)} width="150px" height="77px" alt={result.banco}/>
                                         



                                            {/*resultado da consula em texto*/}
                                              <li>{(result.banco).toUpperCase()}</li>
                                              <li>Valor: R${(result.valor).toFixed(2)}</li>
                                             <li>Parcelas: {result.parcelas}x</li>
                                             <li>Tabela: {(result.tabela).toUpperCase()}</li>
                                             <a  className='simuladorButton' href={whatsapp} rel='noopener noreferrer' target='_blank'> <FaWhatsapp size={40} /> Faça Já</a>
                                             <span>*valores sujeitos a análise de crédito</span>
                                             
                                              </ul>
                                           
                           
                                      ))}
                  
     
                       </fieldset>

        
                            </div>  

                           
                           
           
           
            </div>
       
            </section>
           
        </Fragment>
    )

}