import React, {Fragment} from 'react';
import './styled.css';

import MenuToogle from '../Menu-Toogle';


export default function Menu({facebook, whatsapp}) {
   return(
      <Fragment>

               
         <div className='menu-toolbar-top'>

              
<div className='logo-box'>
<a href='/' rel='noopener noreferrer'><img className='logo' src='https://res.cloudinary.com/daty3sgrj/image/upload/q_auto:good/v1566853342/Logo/logo_300px_lgrark.png' alt='Logo MDfinanceira'/></a>
 </div>  

     <div className='menu-sociais-top '>
      
         <a href={facebook} rel='noopener noreferrer' target='_blank'><img src='https://res.cloudinary.com/daty3sgrj/image/upload/q_auto:good/v1565458727/Logo/Facebook-icon_50px_qvjsel.png' alt='facebook @mdfinanceira2018'/></a>
         <a href='https://www.instagram.com/mdfinanceira' rel='noopener noreferrer' target='_blank'><img src='https://res.cloudinary.com/daty3sgrj/image/upload/q_auto:good/v1565458727/Logo/instagram-icon_50px_ltrt2h.png' alt='instagram @mdfinanceira'/></a>
         <a href={whatsapp} rel='noopener noreferrer' target='_blank'><img src='https://res.cloudinary.com/daty3sgrj/image/upload/q_auto:good/v1565458727/Logo/whatsapp_50px_sou1ok.png' alt='whatzap (21) 98018-2371'/></a>
         <a href={'/#atendimento'} rel='noopener noreferrer' ><img src='https://res.cloudinary.com/daty3sgrj/image/upload/q_auto:good/v1565644133/Logo/logo_tel_q2zn4w.png' alt='telefone (21) 2221-1707 / (21) 2224-5327'/></a>
         
         
         
     </div>
    

 
     <div>
        
     <MenuToogle/> {/*So aparece em celular*/}

    <nav className='nav-bar-web'>  {/*Esconde em celular*/}
       <ul>
            <li><a href="/" rel='noopener noreferrer'>Início</a></li>
            <li><a href="/#produtos" rel='noopener noreferrer'>Produtos</a></li>
            <li><a href="/#parceiros" rel='noopener noreferrer'>Parceiros</a></li>
            <li><a href="/#empresa" rel='noopener noreferrer'>Empresa</a></li> 
            {/*<li><a href="/simulador" rel='noopener noreferrer'>Simulador</a></li>*/}             
        </ul>

     </nav>

     </div>
  
</div>
      

      
      </Fragment>
   )
}