

import React, {Component, Fragment} from 'react';
import { Container, Col, Row } from 'reactstrap';

import './styled.css';
import { MdDone } from "react-icons/md";


export default class PortabilidadeDetalhe extends Component {
    render(){
        return(
            <Fragment>
            <div className="gradientProdutos">
            <div className="tituloProdutos">
                <div className="textoTituloProdutos">Empréstimo Novo</div>
                <div className="textoConteudoProdutos">
                Venha fazer um empréstimo descontado diretamente em folha de pagamento, com taxas de juros reduzidas!
                </div>
            </div>
            </div>


          <Container>
                
                <Row>
                <Col sm="12">
                    <div className="movimentoTela">
                        <div className="texto-Titulo">O que é?</div>
                            <div className="texto-Conteudo"><MdDone/>É um empréstimo descontado diretamente em folha de pagamento, com taxas de juros reduzidas</div>
                            <div className="texto-Titulo">
                            Quem pode fazer?
                            </div>
                            <div className="texto-Conteudo">
                                <div><MdDone/>Militares e pensionistas com margem positiva de no mínimo R$ 45,00</div>
                                
                            </div>
                            <div className="texto-Titulo">
                            Quais são os benefícios ?
                            </div>
                            <div className="texto-Conteudo">    
                            

            
                            <div><MdDone/>Dinheiro rápido, com parcelas pequenas que cabem no seu bolso</div>
                            <div><MdDone/>Sem burocracia e  sem consulta ao SPC e SERASA</div>
                            <div><MdDone/>Taxa de juros reduzidas</div>           
                            </div>
    
                    </div>
                </Col>
                </Row></Container>
                
                </Fragment>
       
       )
    }
}
















