import React, {Component, Fragment} from 'react';
import { Container, Col, Row } from 'reactstrap';

import './styled.css';
import { MdDone } from "react-icons/md";


export default class PortabilidadeDetalhe extends Component {
    render(){
        return(
            <Fragment>
            <div className="gradientProdutos">
            <div className="tituloProdutos">
                <div className="textoTituloProdutos">Refinanciamento</div>
                <div className="textoConteudoProdutos">
                Faça o refinanciamento de seu empréstimo consignado, é o caminho para você que não quitou todas as parcelas e 
                                precisa de mais dinheiro por alguma situação de emergência!
                </div>
            </div>
            </div>


          <Container>
                
                <Row>
                <Col sm="12">


                <div className="movimentoTela">
                        <div className="texto-Titulo">O que é?</div>
                            <div className="texto-Conteudo"><MdDone/>O refinanciamento de empréstimo consignado é o caminho para quem contratou esse tipo de crédito, não quitou todas as parcelas e precisa de mais dinheiro por alguma situação de emergência.</div>
                            <div className="texto-Titulo">
                            Quem pode fazer?
                            </div>
                            <div className="texto-Conteudo">
                                <div><MdDone/>Militares e pensionistas com margem positiva ou negativa.</div>
                                
                            </div>
                            <div className="texto-Titulo">
                            Quais são os benefícios ?
                            </div>
                            <div className="texto-Conteudo">                  
                            <div><MdDone/>Valores liberados rápidos na conta -  ate 03 dias utéis</div>
                            <div><MdDone/>Valor de retorno sem alterar o valor da parcela</div>
                            <div><MdDone/>Não mexemos na margem consignável</div>               
                            <div><MdDone/>Permanecendo no mesmo banco</div>
                            </div>

                    </div>
                </Col>
                </Row></Container>
                
                </Fragment>
       
       )
    }
}






