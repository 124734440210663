export const imgBancos =  (banco)=>{
    if( banco==='bic')  return 'https://res.cloudinary.com/daty3sgrj/image/upload/q_auto:good/v1565361017/bancos/bic_qxg3ah.png';
    if( banco==='bradesco')  return 'https://res.cloudinary.com/daty3sgrj/image/upload/q_auto:good/v1565361006/bancos/bradesco_ruy6xv.png';
    if( banco==='pan')  return 'https://res.cloudinary.com/daty3sgrj/image/upload/q_auto:good/v1565361012/bancos/pan_hgx1yh.png';
    if( banco==='sabemi')  return 'https://res.cloudinary.com/daty3sgrj/image/upload/q_auto:good/v1565361029/bancos/sabemi_wyfbxb.png';
    if( banco==='daycoval')  return 'https://res.cloudinary.com/daty3sgrj/image/upload/q_auto:good/v1565361022/bancos/daycoval_loylil.png';
    if( banco==='maxima')  return 'https://res.cloudinary.com/daty3sgrj/image/upload/q_auto:good/v1565361042/bancos/maxima_tvqme3.png';
    if( banco==='bonsucesso')  return 'https://res.cloudinary.com/daty3sgrj/image/upload/q_auto:good/v1574781261/bancos/logo-bomsucesso_aguiy1.png';
    if( banco==='santander')  return 'https://res.cloudinary.com/daty3sgrj/image/upload/v1597760103/bancos/santander-logo_twt26h.png';
    if( banco=== 'mercantil')  return 'https://res.cloudinary.com/daty3sgrj/image/upload/v1597760680/bancos/mercantil_logo_g5lxjl.png';
}

export const bancos = [
    {_id:1, nome:"bic"},
    {_id:2, nome:"bradesco"},
    {_id:3, nome:"pan"},
    {_id:4, nome:"sabemi"},
    {_id:5, nome:"daycoval"},
    {_id:6, nome:"maxima"},
    {_id:7, nome:"bonsucesso"},
    {_id:8, nome:"santander"},
    {_id:9, nome:"mercantil"}
]