import React, { Component } from "react";
import Slider from "react-slick";
import { Row, Col } from 'reactstrap';
import {imgBancos, bancos} from '../../Services/imgBancos';
import './styled.css';
export default class Parceiros extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 2500,
      slidesToShow: 5,
      slidesToScroll: 1,
      initialSlide: 0,
      autoplay: true,
      autoplaySpeed: 1000,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        }
      ]
    };


    return (
    <Row>
      <Col sm="12" id='parceiros'>
      <div className="bannerParceiros">
      <header className="tituloSite ColorMDPrimary">
                        <h2>Nossos parceiros</h2>
                    
                    <div className="subtituloSite">Encontre os melhores bancos para empréstimo consignado, portabilidade e refinanciamento. Vem para a MD Consultoria Financeira agora!</div>
                    </header>
                    <hr/>
        <Slider {...settings}>
         
            {bancos.map(banco=>(
                <div key={banco._id}>
                    <img src={imgBancos(banco.nome)} width="150px" height="77px" alt={banco.nome}/>
                </div>
            ))}
          
        </Slider>
      </div>
      </Col>
      </Row>

    );
  }
}