import React, {Fragment} from 'react';
import './styled.css';

import Entrada2 from '../../Componentes/Entrada2';
import Atendimento from '../../Componentes/Atendimento';
import Produtos from '../../Componentes/Produtos';
import NossoPublico from '../../Componentes/NossoPublico';
import Parceiros from '../../Componentes/Parceiros';
import MissaoValores from '../../Componentes/MissaoValores';

import Modal from '../../Componentes/Modal';

export default function HomePage({facebook, whatsapp}){
       
        return(
            <Fragment>
            
            <section>
                <Entrada2 
                   whatsapp={whatsapp}
                />   
            </section> 
            
            <section>  
                <Atendimento 
                     whatsapp={whatsapp} 
                     facebook={facebook}
                />
            </section>

            <section>
                <Produtos/>
            </section>

            <section>
                <NossoPublico/>
            </section>

            <section>
                <Parceiros/>
            </section>

            <section>
                <MissaoValores/>    
            </section>    

            <aside>
                <Modal
                    whatsapp={whatsapp} 
                />
            </aside>
                
            </Fragment>
         
        )
        }
