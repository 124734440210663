import React, {Component } from 'react';
import {Modal, ModalBody } from 'reactstrap';

import './style.css'
import { FaCheck, FaRegTimesCircle} from "react-icons/fa";
import {GoX,GoAlert,GoVerified,GoReport } from "react-icons/go";

export default class ModalAlerta extends Component{

state = {
    modal: false
}

async componentDidMount(){
    this.setState({modal:true})
}

toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
}

render() {
  
  const{whatsapp} = this.props; /*pegando a url do whatzap*/

    return(
        <div className="center">
         
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} size="lg">
      
          <ModalBody className='bg-modal'>
            {/*
            <div className="dadosModal">
              <img src="https://res.cloudinary.com/daty3sgrj/image/upload/v1565621991/Fotos/alerta_vermelho_bvhn9g.png" className="img-fluid" alt="" width="100" height="88" /> 
            </div>
            <div className="textoModal text-center">
              <div className="textoMD ColorMDPrimary"><b>MD Consultoria Financeira</b> </div> 
              <div className="textoEmVermelho">
                <b>Não cobramos taxas ou valor antecipado em nossas operações. Denuncie esta prática.</b>
              </div>
              <div>
                <b>Fones: (21)2221-1707 ou (21) 2224-5327</b> 
              </div>
              <div className="textoWpp">
              <a href={whatsapp} rel='noopener noreferrer' target='_blank'><b>WhatsApp: (21)98018-2371</b></a>
              </div>
            </div>*/}
            <FaRegTimesCircle className='exit-modal' onClick={this.toggle} size={40} color={'#fafafa'}/>

<header className='container-modal'>
                <div className='informativo-modal'>
                        
                     <h3 className='titulo-modal'> 
                        <span className='titulo-main-modal'> Evite fraudes</span>
                        <span className='span-main-modal'><GoX size={25} color={'#f00'}/> Não cobramos taxas ou valores antecipados em nossas operações</span>
                        <span className='span-main-modal'><GoAlert size={25} color={'#ffa500'}/> Atenção aos e-mails recebidos</span>
                        <span className='span-main-modal'><GoVerified size={25} color={'#77dd77'}/> Nossos e-mails sempre possuem <b>@mdfinanceira.com.br</b></span>
                        <span className='span-main-modal'><FaCheck size={25} color={'#69baa4'}/> Caso desconfie de algo, entre em contato conosco</span>
                                
                                 
                    </h3>
                    

                </div>

                <div className='familia-modal'>
                <a  className='btn-modal' href={whatsapp} rel='noopener noreferrer' target='_blank'>  <GoReport size={30}/> Precisa de Ajuda? </a>
                <img className='ft-modal' src='https://res.cloudinary.com/daty3sgrj/image/upload/q_auto:good/v1566257727/Fotos/eviteFraudes2_hasruv.png' alt='Simule ja'/>
             
  
                </div>

               
            </header>



          </ModalBody>
         {/*
          <ModalFooter>
         
            <Button color="danger" onClick={this.toggle}>Fechar</Button>
          </ModalFooter>
           */}
        </Modal>
       
      </div>
    )
}}