import React, {Component} from 'react';
import './styled.css';


import { MdRoom, MdMonetizationOn } from "react-icons/md";
import { IoIosTabletPortrait,IoIosCash, IoMdWallet, IoLogoFacebook, IoIosMailUnread, IoIosText, IoIosTime, IoLogoInstagram,
 } from "react-icons/io";
 import {FaWhatsapp } from "react-icons/fa";
import {Container, Row, Col} from 'reactstrap';

export default class Footer extends Component{
    render(){
        const {facebook, whatsapp} = this.props;
        
        return(
          
                <footer className="footer-widget-container">

                  <Container className="containerfooter">
                     <Row>
                         <Col sm="4">
                             <section>

                            
                             <p className='footer-title'>Contato</p>

                            
                            <div> 
                            <a href='/localizacao' className="links" rel='noopener noreferrer' >
                            <p ><MdRoom size={30} color={"#F7BE81"}/>Av. Presidente Vargas, 583 - Sala 1317<br/>Rio de Janeiro - RJ</p>
                            </a>
                            </div>
                          
        
                            
                            <div className='tel-footer'>
                            <IoIosTabletPortrait size={30} color={"#A9F5F2"}/>
                                 <div className='tel-footer-number'>
                                     <span>(21) 2221-1707</span> 
                                     <span>(21) 2224-5327 </span>
                                 </div>
                            </div>
                            <a href='mailto:contato@mdfinanceira.com.br' className="links"><p><IoIosMailUnread size={30} color={"#F2F2F2"}/>Contato@mdfinanceira.com.br</p></a>
                            </section>
                         </Col>

                         <Col sm="4">
                            <section>
                             <p className='footer-title'>Informações</p>
                             <p><IoIosText size={30} color={"#F2F2F2"}></IoIosText>Atendimento presencial / online</p>
                             <p><IoIosTime size={30} color={"#80FF00"}></IoIosTime>Horário de funcionamento<br/>Segunda à sexta-feira de 10h às 18h</p>
                             <a className='links' href={whatsapp} rel='noopener noreferrer' target='_blank'><FaWhatsapp size={30} color={"#80FF00"}></FaWhatsapp>WhatsApp<br/>21 98018-2371</a>
                             </section>
                         </Col>

                         <Col sm="4">
                            <section>
                              <p className='footer-title'>Produtos</p>
                              <a href='/emprestimo-novo' className="links" rel='noopener noreferrer' ><p ><MdMonetizationOn size={25} color={"#81DAF5"}></MdMonetizationOn>Empréstimo novo</p></a>
                              <a href='/portabilidade' className="links" rel='noopener noreferrer' ><p ><IoMdWallet size={25} color={"#81DAF5"}></IoMdWallet>Portabilidade</p></a>
                              <a href='/refinanciamento' className="links" rel='noopener noreferrer' ><p ><IoIosCash size={25} color={"#81DAF5"}></IoIosCash>Refinanciamento</p></a>
                            </section>
                         </Col>
                      </Row>
                   </Container>
            
                     <div className="footer-widget-container2"/>             
                   
                   <div className="container2footer">
                       © 2020 - MD Consultoria Financeira, todos os direitos reservados. Sigam-nos nas Redes Sociais
                       <a className="links espacamentoApps" href={facebook} rel='noopener noreferrer' target='_blank'><IoLogoFacebook size={25} color={"#FAFAFA"}/>Facebook</a>
                       <a className="links espacamentoApps" href='https://www.instagram.com/mdfinanceira' rel='noopener noreferrer' target='_blank'><IoLogoInstagram size={25} color={"#FAFAFA"}/>Instagram</a>
                   </div>
              </footer>
         
        )
    }
}