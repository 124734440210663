import React , {Fragment} from 'react';
import './styled.css';

export default function Avatar({whatsapp}){
    return(
        <Fragment>
             <a className='avatar-text'  href={whatsapp} rel='noopener noreferrer' target='_blank'>
            <img className='avatar-faleconosco' src='https://res.cloudinary.com/daty3sgrj/image/upload/q_auto:good/v1566419998/faleConosco/faleconosco3_oxp7jt.png' alt='Fale Conosco'/>
            </a>
        </Fragment>
    )
}