import React, {Component, Fragment} from 'react';
import GoogleMaps from "simple-react-google-maps";


  export default class MapContainer extends Component{
    render(){
        return(<Fragment>

            <GoogleMaps
              apiKey={"AIzaSyDQ1CI1X-oEvtSoeUGpV01pqyH1NEfgqCk"}
              style={{height: "600px", width: "100%"}}
              zoom={15}
              center={{lat:-22.902223, lng: -43.180737}}
              markers={{lat: -22.902223, lng: -43.180737}} //optional
              />
              
            </Fragment>
        )
    }
}