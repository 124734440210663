import React, {Component} from 'react';
import './styled.css';
import { Container, Row, Col } from 'reactstrap';
import { MdDomain, MdAssignment  } from "react-icons/md";
import { IoIosBulb  } from "react-icons/io";

import { FaHandsHelping, FaBalanceScale, FaUserTie} from "react-icons/fa";
export default class MissaoValores extends Component{
    render(){
        return(
            <div className="missaoValores text-center" id='empresa'>
                <Container className="missaoValores-container">
                    <header className="textOferecemos ColorMDPrimary">
                        <h2>Oferecemos a você</h2>
                        <div className="subtituloSite">
                            Consultores altamente treinados para te oferecer o que há de melhor.
                        </div>
                        <hr/>
                    </header>
                    
                    <Row>
                        <Col sm="3">
                        <img src="https://res.cloudinary.com/daty3sgrj/image/upload/q_auto:good/v1565638858/Fotos/familia-feliz_z3irne.png" alt="missao e valores" className="img-fluid"/>
                        </Col>
                        
                           
                    
                            <Col sm="9">
                            <div className="apresentacaoMD-missao">
                                <span className="dropcap">A</span>
                             MD Financeira promove acesso ao crédito, 
                            com agilidade e segurança, a todos os brasileiros, sem distinção de renda ou classe social.
                            </div>
                            <hr/>

                                 
                            <Row className='box-missao'>
                        <Col sm="4">
                            <div > 
                                <MdAssignment size={30} className="iconMissao"/>                      
                            <h4 className="headerMissao">
                            Transparência
                            </h4>
                            <p className="pMissao">Acreditamos que ela é a base de confiança no relacionamento com todos os públicos que interagimos.
                            </p>
                            </div>
                        </Col>
                        <Col sm="4">
                        <div>  
                        <IoIosBulb size={30} className="iconMissao"/>  
                            <h4 className="headerMissao">
                            Agilidade e inovação
                            </h4>
                            <p className="pMissao">Atender as expectativas dos nossos clientes com soluções ágeis e inovadoras frente ao mercado. </p>
                            
                            </div>
                        
                        </Col>
                        <Col sm="4">
                        <div>  
                        <FaHandsHelping size={30} className="iconMissao"/>  
                            <h4 className="headerMissao">
                            Relacionamento
                            </h4>
                            <p className="pMissao">Interagir em todos os niveis na busca constante pela liderança do mercado que atuamos. </p>
                            </div>
                        </Col>
                    </Row>
                   
                    <Row className='box-missao'>
                        <Col sm="4">
                        <div>  
                        <FaBalanceScale size={30} className="iconMissao"/>  
                            <h4 className="headerMissao">
                            Ética
                            </h4>
                            <p className="pMissao">Primamos pela conduta ética em nossos negócios, trabalhando com transparência em todos os níveis de relacionamento. </p>
                            
                            </div>
                        </Col>
                        <Col sm="4">
                        <div>  
                        <MdDomain size={30} className="iconMissao"/>  
                            <h4 className="headerMissao">
                            Solidez Empresarial
                            </h4>
                            <p className="pMissao">Garantir, através de gestão responsável, a perenidade do negócio. </p>
                            </div>
                        </Col>
                        <Col sm="4">
                        <div>         
                        <FaUserTie size={30} className="iconMissao" />               
                            <h4 className="headerMissao">
                            Educação profissional
                            </h4>
                            <p className="pMissao">Promover o desenvolvimento contínuo, investindo na formação técnica e comportamental dos profissionais.
                            </p>
                            </div>
                        </Col>
                    </Row>
                            </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}