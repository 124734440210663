import React, { Fragment, useState} from 'react';
import './styled.css';

export default function MenuToogle(){

    const [toggler, setToggler] = useState('');

   async function handleChecked(){
     await setToggler(!toggler)
    }

        return(
           <Fragment>
           
     <div className="menu-wrap-menu">  
<nav>
   <input type="checkbox" className="toggler" checked={toggler} onChange={handleChecked}/>
       <div className="hamburger"><div></div></div>
       <div className="menu">
           <div>
               <div>
                   <ul>
                    <li><a href="/"  rel='noopener noreferrer' onClick={handleChecked} >inicio</a></li>
                    <li><a href="/#produtos" rel='noopener noreferrer' onClick={handleChecked}>Produtos</a></li>
                    <li><a href="/#parceiros"  rel='noopener noreferrer' onClick={handleChecked}>Parceiros</a></li>
                    <li><a href="/#empresa" rel='noopener noreferrer' onClick={handleChecked}>Empresa</a></li>  
                    <li><a href="/simulador" rel='noopener noreferrer'>Simulador</a></li>               
                   </ul>


          <a href='/' rel='noopener noreferrer'><img className='logo-branca' src='https://res.cloudinary.com/daty3sgrj/image/upload/q_auto:good/v1565382486/Logo/LogoMD_semfundo-Branco_empwqa.png' alt='Logo MDfinanceira'/></a>
         
               </div>
           </div>
        </div>

</nav>


       

</div>



          
                      
            

                 
           </Fragment>
        )
    
}