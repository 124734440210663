import React, {Component} from 'react';
import './styled.css';
import { Container, Row, Col } from 'reactstrap';

import { MdPhone, MdEmail, MdLocationCity } from "react-icons/md";
import { FaWhatsapp, FaInstagram, FaFacebook } from "react-icons/fa";

export default class Atendimento extends Component{


    render(){
        const {whatsapp} = this.props;
        const {facebook} = this.props;
        return(
            <div className="atendimentoDivPrincipal">
                <Container>
                    <header id='atendimento' className="tituloSite ColorMDPrimary">
                        <h2>Atendimento</h2>
                        <hr/>
                    </header>
                    <Row> {/*Primeira linha*/}
                        <Col sm="4" className="atendimentoEspacamento"> {/*Primeira coluna*/}
                            <Row> {/*Linha dentro da primeira coluna*/}
                                <Col xs="12" sm="12" md="12" lg="12">
                                    <a href="tel:+5502122211707"><MdPhone size={40}  className="iconMissao"></MdPhone></a>
                                    <label className="atendimentoLabelPrincipal">Telefones</label>
                                </Col>
                                <Col sm="12">
                                    <div>
                                        <label>Nossos números: <a href="tel:+5502122211707">(21)2221-1707</a> ou <a href="tel:+5502122245327">(21)2224-5327</a></label>
                                    </div>
                                </Col>
                            </Row>
                        </Col>

                        <Col sm="4" className="atendimentoEspacamento"> {/*Segunda coluna*/}
                            <Row> {/*Linha dentro da segunda coluna*/}
                                <Col xs="12" sm="12" md="12" lg="12">
                                <a href='mailto:contato@mdfinanceira.com.br' className="links"><MdEmail size={40} className="iconMissao"></MdEmail></a>
                                    <label className="atendimentoLabelPrincipal">E-mail</label>
                                </Col>
                                <Col sm="10">
                                    <div>
                                        <label>Contate-nos via e-mail: contato@mdfinanceira.com.br</label>
                                    </div>
                                </Col>
                            </Row>
                        </Col>

                        <Col sm="4" className="atendimentoEspacamento"> {/*Terceira coluna*/}
                            <Row> {/*Linha dentro da terceira coluna*/}
                                <Col xs="12" sm="12" md="12" lg="12">
                                    <MdLocationCity size={40} className="iconMissao"></MdLocationCity>
                                    <label className="atendimentoLabelPrincipal">Empresa</label>
                                </Col>
                                <Col sm="10">
                                    <div>
                                        <label>Deseja ter atendimento presencial? Conheça nossa localização!</label>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row>{/*Segunda linha*/}
                        <Col sm="4" className="atendimentoEspacamento"> {/*Quarta coluna*/}
                            <Row> {/*Linha dentro da quarta coluna*/}
                                <Col xs="12" sm="12" md="12" lg="12">
                                    <a href={whatsapp} rel='noopener noreferrer' target='_blank'>
                                        <FaWhatsapp size={40} className="iconMissao"></FaWhatsapp>
                                    </a>
                                        <label className="atendimentoLabelPrincipal">Whatsapp</label>
                                </Col>
                                <Col sm="10">
                                    <a href={whatsapp} rel='noopener noreferrer' target='_blank'>
                                        <div className="atendimentoLink">
                                            <label>
                                                Clique e será redirecionado para o nosso aplicativo de mensagem.
                                            </label>
                                        </div>
                                    
                                    </a>
                                </Col>
                            </Row>
                        </Col>

                        <Col sm="4" className="atendimentoEspacamento"> {/*Quinta coluna*/}
                            <Row> {/*Linha dentro da quinta coluna*/}
                                <Col xs="12" sm="12" md="12" lg="12">
                                    <a href='https://www.instagram.com/mdfinanceira' rel='noopener noreferrer' target='_blank'>
                                        <FaInstagram size={40} className="iconMissao"></FaInstagram>
                                    </a>
                                    <label className="atendimentoLabelPrincipal">Instagram</label>
                                </Col>
                                <Col sm="10">
                                    <a href='https://www.instagram.com/mdfinanceira' rel='noopener noreferrer' target='_blank'>

                                        <div className="atendimentoLink">
                                            <label>
                                                Clique e siga nossa rede social para acompanhar nossas novidades.                                     
                                            </label>
                                        </div>
                                    </a>
                                </Col>
                            </Row>
                        </Col>

                        <Col sm="4" className="atendimentoEspacamento"> {/*Sexta coluna*/}
                            <Row> {/*Linha dentro da sexta coluna*/}
                                <Col xs="12" sm="12" md="12" lg="12" >
                                    <a href={facebook} rel='noopener noreferrer' target='_blank'>
                                        <FaFacebook size={40} className="iconMissao"></FaFacebook>
                                    </a>
                                        <label className="atendimentoLabelPrincipal">Facebook</label>
                                </Col>
                                <Col sm="10">
                                    <a  href={facebook} rel='noopener noreferrer' target='_blank'>
                                        <div className="atendimentoLink">
                                            <label>
                                                Clique, curta e compartilhe nossas notícias pela página do facebook.
                                            </label>
                                        </div>
                                    </a>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}