import React, {Fragment, Component} from 'react';
import './styled.css';

import PortabilidadeDetalhe from '../../Componentes/Produtos/Portabilidade-Detalhe';
import RefinanciamentoDetalhe from '../../Componentes/Produtos/Refinanciamento-Detahe';
import NovoDetalhe from '../../Componentes/Produtos/Novo-Detalhe';


export default class DetalhesProdutos extends Component{


    render(){

      const {pathname} = this.props.location;

        return(
            <Fragment>

             {pathname==='/portabilidade' && (
                    <PortabilidadeDetalhe/>
             )}

            {pathname==='/emprestimo-novo' && (
                   <NovoDetalhe/>
             )}

            {pathname==='/refinanciamento' && (
                    <RefinanciamentoDetalhe/>
             )}
             
                
               
            </Fragment>
         
        )
    }
}