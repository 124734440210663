import React, { Component } from "react";
import './style.css';
import ScrollUpButton from "react-scroll-up-button";
import { FaChevronUp } from "react-icons/fa";


export default class VoltarInicio extends Component {
    render() {
      return (
        <div>
        <ScrollUpButton
          ContainerClassName="AnyClassForContainer"
          TransitionClassName="AnyClassForTransition"
          ShowAtPosition={300}
          AnimationDuration={750}
        >
            <FaChevronUp color="white" size="25"/>
        </ScrollUpButton>
      </div>
    );
  }
}