import React ,{useState, useEffect} from 'react';
import {BrowserRouter} from 'react-router-dom';

import Menu from './Componentes/Menu';
import Routes from './routes';
import Footer from './Componentes/Footer';
import Avatar from './Componentes/Avatar';
import VoltarInicio from './Componentes/VoltarInicio';

import {detectar_mobile_face} from './Services/face'
import {detectar_mobile_what} from './Services/whats'

export default function App() {


  const [facebook, setFacebook] = useState('');
  const [whatsapp, setWhatsapp] = useState('');

  useEffect( () => { changeFace();},[facebook]);
  useEffect( () => {changeWhats();},[whatsapp]);

 
  async function changeFace(){
     const face = await detectar_mobile_face();
        await setFacebook(face);
      
    }

    async function changeWhats(){
      const whats = await detectar_mobile_what();
        await setWhatsapp(whats);    
      }

  return (
    <BrowserRouter> 
    
      <header>
         <Menu
          whatsapp={whatsapp}
          facebook={facebook}
        />  
      </header>
        
      <main>
        <Routes 
        whatsapp={whatsapp}
        facebook={facebook}
       />
      </main>

      <aside>
        <section>
           <Avatar 
          whatsapp={whatsapp} 
           />
        </section>

        <section>
          <VoltarInicio/>
        </section>  
      </aside>
    
   
      <Footer
         facebook={facebook}
         whatsapp={whatsapp}
       />
    
       
      
    </BrowserRouter>
  );
}


