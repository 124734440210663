import React, {Fragment} from 'react';

import './styled.css';

import { FaCheck, FaWhatsapp, FaDollarSign} from "react-icons/fa";
import {GoX,GoAlert,GoVerified,GoReport } from "react-icons/go";

import Slider from "react-slick";

export default function Entrada2({whatsapp}){

   

    const settings = {
        dots: true,
        dotsClass: "slick-dots slick-thumb dot-entrada2",
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 3000,
        cssEase: "ease-in-out",
        fade: true   
      };

      
    return(
        <Fragment>

                      
<Slider {...settings}>

 
          <article> {/* parte 1  */}
        
        <header className='container-entrada2'>
                <div className='informativo-entrada2'>
                        
                     <h1 className='titulo-entrada2'> 
                        <span className='titulo-main-entrada2'> Precisando de dinheiro?</span>
                        <span className='span-main-entrada2'><FaCheck size={25} color={'#69baa4'}/> Empréstimo consignado em até 24 hrs</span>
                        <span className='span-main-entrada2'><FaCheck size={25} color={'#69baa4'}/> Parcelas de até 72x com desconto em folha</span>
                        <span className='span-main-entrada2'><FaCheck size={25} color={'#69baa4'}/> Saia do papel e invista em seus sonhos</span>
                                
                                 
                    </h1>
                    {/*<a  className='btn-entrada' href={'/simulador'} rel='noopener noreferrer' > <FaWhatsapp size={40}/> Simule Já</a>*/}
                    <a  className='btn-entrada' href={whatsapp} rel='noopener noreferrer' target='_blank'>  <GoReport size={40}/> Precisa de ajuda? </a>
                </div>

                <div className='familia-entrada2'>
            
              <img className='familia-entrada2-logo' src='https://res.cloudinary.com/daty3sgrj/image/upload/q_auto:good/v1566586135/Fotos/familia1_dy7jne.png' alt='Simule ja'/>
  
                </div>
            </header>

         
          </article> 



          <article>{/* parte 2  */}

              
          <header className='container-entrada3'>

<div className='familia-entrada3'>

<img className='familia-entrada2-logo' src='https://res.cloudinary.com/daty3sgrj/image/upload/q_auto:good/v1566047469/Fotos/viajen2_g9k8zy.png' alt='Simule ja'/>

  </div>

  
    <div className='informativo-entrada3'>
            
         <h2 className='titulo-entrada3'> 
            <span className='titulo-main-entrada3'>Faça a viagem dos seus sonhos</span>
            <span className='span-main-entrada3'><FaCheck size={25} color={'#69baa4'}/> Trabalhamos com os menores juros do mercado </span>
            <span className='span-main-entrada3'><FaCheck size={25} color={'#69baa4'}/> Descontado diretamente em folha</span>
            <span className='span-main-entrada3'><FaCheck size={25} color={'#69baa4'}/> Sem burocracia e sem consulta ao SPC e SERASA</span>

                    
                     
        </h2>
        {/*<a className='btn-entrada3' href={'/simulador'} rel='noopener noreferrer' > <FaDollarSign size={40}/> Faça já sua simulação</a>*/}
        <a  className='btn-entrada' href={whatsapp} rel='noopener noreferrer' target='_blank'>  <GoReport size={40}/> Precisa de ajuda? </a>
    </div>

   
</header>

          </article>
      
 
         
 
          <article > {/*parte 3 */}
        
        <header className='container-entrada4'>
                <div className='informativo-entrada2'>
                        
                     <h3 className='titulo-entrada2'> 
                        <span className='titulo-main-entrada4'> Evite fraudes</span>
                        <span className='span-main-entrada4'><GoX size={25} color={'#f00'}/> Não cobramos taxas ou valores antecipados em nossas operações</span>
                        <span className='span-main-entrada4'><GoAlert size={25} color={'#ffa500'}/> Atenção aos e-mails recebidos</span>
                        <span className='span-main-entrada4'><GoVerified size={25} color={'#77dd77'}/> Nossos e-mails sempre possuem <b>@mdfinanceira.com.br</b></span>
                        <span className='span-main-entrada4'><FaCheck size={25} color={'#69baa4'}/> Caso desconfie de algo, entre em contato conosco</span>
                                
                                 
                    </h3>
                    <a  className='btn-entrada' href={whatsapp} rel='noopener noreferrer' target='_blank'>  <GoReport size={40}/> Precisa de ajuda? </a>

                </div>

                <div className='familia-entrada4'>
            
             <img className='familia-entrada2-logo' src='https://res.cloudinary.com/daty3sgrj/image/upload/q_auto:good/v1566257727/Fotos/eviteFraudes2_hasruv.png' alt='Simule ja'/>

  
                </div>
            </header>

      
          </article> 
        


        </Slider>
  
        </Fragment>
    )
}