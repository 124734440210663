import React, {Component} from 'react';
import './styled.css';
import { Container, Row, Col } from 'reactstrap';

export default class NossoPublico extends Component{
    render(){
        return(
            <div className="nossoPublicototal text-center">
                <Container className="nossoPublicocontainer">
                    <header className="tituloSite ColorMDPrimary">
                        <h2>Nosso público</h2>
                    <div className="subtituloSite">Na MD Consultoria Financeira oferece mais facilidades para quem é das Forças Armadas, SIAPE ou do Estado do RJ. O crédito é simples e rápido!</div> 
                    <hr/>
                    </header>
                    <Row>
                        <Col className="nossoPublicoefeitoEsquerda" sm="4">
                        <article>
                            <div className="grow pic">
                            <img src="https://res.cloudinary.com/daty3sgrj/image/upload/q_auto:good/v1566851093/PUBLICO/exercito_ckaioj.png" alt="Forças Armadas" className="img-fluid"/>
                            </div>
                            
                            <h6 className="nossoPublicoservices-default-title ColorMDPrimary">
                               <b>Forças Armadas do Brasil</b> 
                            </h6>
                            <p className="nossoPublicoservices-default-text">O Crédito Consignado para Militares do Exército, da Marinha e da Aeronáutica é uma modalidade de empréstimo 
                            consignado no qual o dinheiro é disponibilizado imediatamente com taxa de juros reduzidas.</p>
                        </article> 
                        </Col>
                        <Col className="nossoPublicoefeitoCima" sm="4">
                        <article> 
                            <div className="grow pic">
                                <img src="https://res.cloudinary.com/daty3sgrj/image/upload/q_auto:good/v1566852775/PUBLICO/siape_p4dkj3.png" alt="SIAPE" className="img-fluid"/>
                            </div>
                            <h6 className="nossoPublicoservices-default-title ColorMDPrimary">
                              <b>SIAPE</b>  
                            </h6>
                            <p className="nossoPublicoservices-default-text">O Crédito Consignado para Servidores Federais – SIAPE é uma 
                            modalidade de crédito com baixas taxas e prazo de até 96 meses, na qual as parcelas são descontadas diretamente da folha de pagamento.</p>
                        </article>
                        </Col>
                        <Col className="nossoPublicoefeitoDireita" sm="4">
                        <article>
                            <div className="grow pic">
                                <img src="https://res.cloudinary.com/daty3sgrj/image/upload/q_auto:good/v1565122782/PUBLICO/servidor-publico_jdmn7n.png" alt="Estado RJ" className="img-fluid"/>
                            </div>
                            <h6 className="nossoPublicoservices-default-title ColorMDPrimary">
                               <b>Estado RJ</b> 
                            </h6>
                            <p className="nossoPublicoservices-default-text">
                            O Crédito Consignado para o Estado do RJ é uma modalidade de crédito disponível para servidores com baixas taxas e prazos longos, 
                            na qual as parcelas são descontadas diretamente da folha de pagamento.
                            </p>
                         </article>   
                        </Col>
                    </Row>
                </Container>
            </div>
        
        )
    }
}