import React, {Component} from 'react';
import './styled.css';
import { Container, Row, Col, } from 'reactstrap';

import { IoIosCash, IoMdWallet } from "react-icons/io";
import { MdMonetizationOn  } from "react-icons/md";

export default class Produtos extends Component{
    render(){
        return(
         <div>
            <Container>
            <header id='produtos' className="tituloSite ColorMDPrimary">
                        <h3>Produtos</h3>
                        <hr/>
                    </header>
           

                  <Row>
                        

                       
                    <Col lg="4" md="12" sm="12"   className="right">
                    <a href='/portabilidade' className="animation" rel='noopener noreferrer' >
                            <article>
                                <div className="detalheBox">
                                <IoMdWallet className="iconProdutos"/>Portabilidade
                                <img className="imgDentroBox" alt="PortabilidadeImagem" src="https://res.cloudinary.com/daty3sgrj/image/upload/q_auto:good/v1566395100/Fotos/portabilidade_vbf5s6.png"></img>
                                </div> 
                                <div className="detalheBoxHover">
                                    <h4 className="iconProdutosDentroHover"><IoMdWallet className="iconProdutosDentroHover"/>
                                        Portabilidade
                                    </h4>
                                
                                    <p> Portar sua operação, com um novo contrato e uma nova instituição, utilizando seu saldo devedor com a instituição atual e melhorando
                                        suas ofertas e cláusulas contratuais! </p>
                                </div>
                                <div className="detalheBoxMobile">
                                Portar sua operação, com um novo contrato e uma nova instituição, utilizando seu saldo devedor com a instituição atual e melhorando
                                        suas ofertas e cláusulas contratuais!
                                </div>

                            </article>
                            </a>

                        </Col>

                        <Col lg="4" md="12" sm="12"  className="north">
                        <a href='/emprestimo-novo' className="animation" rel='noopener noreferrer' >
                        <article>
                                <div className="detalheBox">
                                <MdMonetizationOn className="iconProdutos"/>Empréstimo Novo
                                <img className="imgDentroBox" alt="EmprestimoNovoImagem" src="https://res.cloudinary.com/daty3sgrj/image/upload/q_auto:good/v1566853045/Fotos/emprestimo_novo_n0obbd_ttsaoq.png"></img>
                                </div> 
                                <div className="detalheBoxHover">
                                    <h4 className="iconProdutosDentroHover"><MdMonetizationOn className="iconProdutosDentroHover"/>
                                    Empréstimo Novo
                                    </h4>
                                
                                    <p> Venha fazer um empréstimo descontado diretamente em folha de pagamento, com taxas de juros reduzidas! </p>
                                </div>
                                <div className="detalheBoxMobile">
                                    Venha fazer um empréstimo descontado diretamente em folha de pagamento, com taxas de juros reduzidas! 
                                </div>
                            </article>
                            
                                       
                        </a>
                     </Col>


                        <Col lg="4" md="12" sm="12" className="left">
                        <a href='/refinanciamento' className="animation" rel='noopener noreferrer' >

                        <article>
                                <div className="detalheBox">
                                <IoIosCash className="iconProdutos"/>Refinanciamento 
                                <img className="imgDentroBox" alt="ImagemRefinanciamento" src="https://res.cloudinary.com/daty3sgrj/image/upload/v1566931859/Fotos/refinanciamento_yd50u1_nuya5f.png"></img>
                                </div> 
                                <div className="detalheBoxHover">
                                    <h4 className="iconProdutosDentroHover"><IoIosCash className="iconProdutosDentroHover"/>
                                    Refinanciamento 
                                    </h4>
                                
                                    <p> Faça o refinanciamento de seu empréstimo consignado, é o caminho para você que não quitou todas as parcelas e 
                                    precisa de mais dinheiro por alguma situação de emergência!  </p>
                                </div>
                                <div className="detalheBoxMobile">
                                    Faça o refinanciamento de seu empréstimo consignado, é o caminho para você que não quitou todas as parcelas e 
                                    precisa de mais dinheiro por alguma situação de emergência!  
                                </div>
                            </article>
                            </a>
                        </Col>
                    </Row>               
                </Container>
            </div>
        )
    }
}