import React  from 'react';
import {Switch, Route} from 'react-router-dom';

import HomePage from './Pages/HomePage';
import DetalhesProdutos from './Pages/DetalhesProdutos';
import MapContainer from './Pages/Maps';
import Simulador from './Pages/Simulador';

export default function Routes({facebook, whatsapp}){
   
    return(
        <Switch>
            <Route path='/' exact render={(...props)=> <HomePage {...props} facebook={facebook} whatsapp={whatsapp}/>}  />
            <Route path='/portabilidade' exact component={DetalhesProdutos}/>
            <Route path='/emprestimo-novo' exact component={DetalhesProdutos}/>
            <Route path='/refinanciamento' exact component={DetalhesProdutos}/>
            <Route path='/localizacao' exact component={MapContainer}/>
            <Route path='/simulador' exact render={(...props)=> <Simulador {...props} facebook={facebook} whatsapp={whatsapp}/>}  />
         
        </Switch>
            )
}
